import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { config } from '@app/core/app.config';
import { MessageService } from 'primeng/api';
import { catchError, map } from 'rxjs/operators';
import { ChangePassword, CreatUserInfo, UpdateUserInfo, UserLogin } from '@app/core/models';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';

@Injectable()
export class UserService {
	constructor(
		private httpService: HttpClientService,
		private messageService: MessageService, // Toast
		private http: HttpClient, // native HttpClient
		private router: Router,
		private httpClient: HttpClient,
		private store: Store
	) {}

	//** Catch Err function
	isCatchErrCode(x, apiName) {
		if (x.status === 401) {
			this.messageService.add({
				severity: 'error',
				summary: `登入已過期`,
				detail: '請重新登入',
				icon: 'bounce animated',
				life: 5000,
			});
			this.store.dispatch(new fromUser.LogoutAction({}));
		} else {
			this.messageService.add({
				severity: 'error',
				summary: `API ${apiName} Error, status: ${x.status} `,
				detail: '請聯繫管理員',
				icon: 'bounce animated',
				life: 5000,
			});
		}
	}

	//** 建立 Error 抓錯
	getError(res, apiName) {
		if (res.code === '400') {
			this.messageService.add({
				severity: 'error',
				summary: `API ${apiName} Error, ${res.exception} `,
				detail: '請聯繫管理員',
				icon: 'bounce animated',
				life: 5000,
			});
			return res;
		} else if (res.code === '401') {
			this.messageService.add({
				severity: 'error',
				summary: `登入已過期`,
				detail: '請重新登入',
				icon: 'bounce animated',
				life: 5000,
			});
			this.store.dispatch(new fromUser.LogoutAction({}));
		} else if (res.code === '1015') {
			this.messageService.add({
				severity: 'error',
				summary: res.message,
				// detail: '請重新登入',
				icon: 'bounce animated',
				life: 5000,
			});
			this.store.dispatch(new fromUser.LogoutAction({}));
		} else if (res.code !== '000') {
			return res;
		} else {
			return res;
		}
	}

	/** 新增使用者 */
	public createMember(params: {
		Email: string;
		Name: string;
		Phone: string;
		Password: string;
		Birth: string;
		IdNo: string;
		IdentificationDate: string;
		Gender: number;
		DeviceId: string;
		Platform: string;
	}): Observable<any> {
		const apiName = 'CreateMember';
		return this.httpService.httpPost(`${config.FEMemberUrl}/` + apiName, params).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	/** 單一登入 */
	public singleSignIn(dataSrc: unknown): Observable<any> {
		return this.httpService.httpPost(`${config.SingleUrl}/SingleSignIn`, dataSrc);
	}

	// ** 使用者-使用者登入  **/
	public userLogin(params: UserLogin): Observable<any> {
		const apiName = 'MemberLogin';
		return this.httpService.httpPost(`${config.FEMemberUrl}/` + apiName, params).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	// ** 修改密碼  **/
	public changePassword(params: { UId: string; Password: string; NewPassword: string }): Observable<any> {
		const apiName = 'ChangePassword';
		return this.httpService.httpPost(`${config.FEMemberUrl}/` + apiName, params).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	// ** 重設密碼  **/
	public resetPassword(params: {
		Account: string;
		ChangePW: string;
		DeviceId: string;
		Platform: string;
	}): Observable<any> {
		const apiName = 'ResetPassword';
		return this.httpService.httpPost(`${config.FEMemberUrl}/` + apiName, params).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	// ** 修改使用者  **/
	public updateMemberInfo(params: UpdateUserInfo): Observable<any> {
		const apiName = 'UpdateMemberInfo';
		return this.httpService.httpPost(`${config.FEMemberUrl}/` + apiName, params).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	// ** 使用者登出  **/
	public userLogout(params: UserLogin): Observable<any> {
		const apiName = 'UserLogout';
		return this.httpService.httpPost(`${config.FEMemberUrl}/UserLogout`, params).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	// ** 會員身份驗證（身分證 or 手機）  **/
	public verifyMemberIdentityInfo(param: { Phone: string; IdNo: string }): Observable<any> {
		const apiName = 'VerifyMemberIdentityInfo';
		return this.httpService.httpPost(`${config.FEMemberUrl}/` + apiName, param).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	// ** 會員註冊資訊驗證（身分證、手機、身障日期）  **/
	public checkMemberRegister(param: { Phone: string; IdNo: string; IdentificationDate: string }): Observable<any> {
		const apiName = 'CheckMemberRegister';
		return this.httpService.httpPost(`${config.FEMemberUrl}/` + apiName, param).pipe(
			tap((res) => console.log(res)),
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	// ** 取得網頁登入驗證碼圖形  **/
	public getCaptcha(): Observable<any> {
		const apiName = 'CaptchaAsync';
		return this.http
			.get(`${config.AuthorizationUrl}/CaptchaAsync`, {
				responseType: 'blob',
				observe: 'response', // Make sure to observe the full HttpResponse
			})
			.pipe(
				map((response) => {
					const captchaImageBlob = <Blob>response.body;
					const captchaKey = response.headers.get('Captcha-Key');
					return { captchaImageBlob, captchaKey };
				}),
				catchError((x) => {
					this.isCatchErrCode(x, apiName);
					return of();
				})
			);
	}

	// ** KickOut Token  **/
	public kickOutToken(param: {
		Account: string;
		Token: string;
		DeviceId: string;
		Platform: string;
	}): Observable<any> {
		const apiName = 'KickOutToken';
		return this.httpService.httpPost(`${config.AuthorizationUrl}/` + apiName, param).pipe(
			tap((res) => console.log(res)),
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	// ** 手機是否重複（修改手機號碼 => 手機是否重複）  **/
	public verifyMemberPhone(param: { Phone: string }): Observable<any> {
		const apiName = 'VerifyMemberPhone';
		return this.httpService.httpPost(`${config.FEMemberUrl}/` + apiName, param).pipe(
			tap((res) => console.log(res)),
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	// ** 聽人註冊驗證(是否為聽人)  **/
	public checkHearingPeople(param: { IdNo: string }): Observable<any> {
		const apiName = 'CheckHearingPeople';
		return this.httpService.httpPost(`${config.FEMemberUrl}/` + apiName, param).pipe(
			tap((res) => console.log(res)),
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	// ** 取得帳號資訊  **/
	public getMemberInfo(param: {} = {}): Observable<any> {
		const apiName = 'GetMemberInfo';
		return this.httpService.httpPost(`${config.FEMemberUrl}/` + apiName, param).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}
}
